@media (min-width: 768px) {
  .welcomeContainer {
    margin-top: 0;

    h1 {
      padding: 0;
      margin: 0;
    }
  }
}

.sectionHead {
  padding-top: 40px;
  padding-bottom: 16px;
  max-width: 640px;
}

// Mobile
@media (max-width: 768px) {
  .welcomeContainer {
    padding-bottom: 24px;
  }
  .sectionHead {
    padding-top: 0;
    padding-bottom: 0;
  }
}
