@import "@hagerty/design-system/src/variables/_typeset.scss";

.header {
  display: flex;
  align-items: center;
  padding-top: 16px;
}

@media (max-width: 546px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.headingText {
  font-family: $helvetica-neue;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 0;
}
