.skipMain:focus,
.skipMain:active {
  position: absolute;
  color: #fff;
  background-color: #007bb3;
  left: auto;
  top: auto;
  width: 200px;
  height: auto;
  overflow: auto;
  padding: 13px 24px;
  border-radius: 28px;
  text-align: center;
  z-index: 101;
}
