@import "@hagerty/design-system/src/_variables";

.container {
  margin-top: 32px;
  width: 100%;
  position: relative;

  :where(address) {
    margin-bottom: unset;
  }
}

.buttonLink {
  background: none;
  color: $color-brand;
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
}

.infoWithLink {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 479px) {
    justify-content: space-between;
    margin-top: 5px;
  }
}

.withButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  line-height: 26px;
  width: 100%;
  justify-content: space-between;

  & > *:not(:first-child) {
    margin-left: 16px;
  }
}

.alert {
  position: fixed;
  bottom: 24px;
  right: 24px;

  .message {
    display: flex;
    align-items: center;
  }
}

.descriptionText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $color-dark-1;
  margin: 2px 0px;
}

.rowWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: solid 1px #484c511b;
}

.rowWrapperName {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 17px;
  padding-bottom: 32px;
  border-bottom: solid 1px #484c511b;
}

.rowWrapperCommunication {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 32px;
  padding-bottom: 17px;
}

.editLink {
  color: $color-brand;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

// Mobile - Move information and buttons below title
@media (max-width: 719px), (min-width: 768px) and (max-width: 1024px) {
  .rowWrapper,
  .rowWrapperCommunication,
  .rowWrapperName {
    flex-direction: column;
  }
}
