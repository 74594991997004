@import "@hagerty/design-system/src/font-faces/helvetica-neue";

@import "./_base";
@import "@hagerty/design-system/src/utilities/sizing/index";
@import "@hagerty/design-system/src/utilities/grid/index.scss";
@import "@hagerty/design-system/src/variables/_typeset.scss";
@import "@hagerty/design-system/src/index.scss";
@import "@hagerty/global-navigation/styles.css";

html {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  background-color: #f7f9fa;
}

body:has(#account-settings) {
  --header-bg-color: var(--sand);
  background-color: var(--sand);
}

hr {
  margin: 48px 0;
  border-top: 1px solid $color-dark-1;
  opacity: 0.1;
}

b {
  font-weight: 500;
}

// Remove arrows from input with number type

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

// https://www.bram.us/2020/03/01/prevent-content-from-being-hidden-underneath-a-fixed-header-by-using-scroll-margin-top/

* {
  scroll-margin-top: $space-xxl;
}

.site-header__logo {
  margin-left: 0;
}

// These are used in various layouts for data sections, at some point we might make a control that contains these instead

.login-data-table_row {
  padding: 16px 0;
}

.login-data-table_row-body {
  text-align: right;
}

.login-data-table_header {
  padding: 0 0 32px 0;
}

.login-data-table .divider {
  margin-top: 32px;
}
