.container {
  // override HDS modal_container_scrollable which sets `height: calc(100% - 3rem*2);` causing vertical centering issues
  height: auto;
  margin: 0;
}

.modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #{#090a0b1a};
  z-index: 100000;
}

.modal__container {
  transform: none;
  overflow: hidden; // without this, the PDF will square off the corners on the bottom on the dialog
}

.modal__body {
  max-height: calc(
    100vh - 185px
  ); // this is done by the HDS JS that we don't use
  position: relative; // keep the iframe withing the modal__body. without this, it overrides the header
}
