@import "@hagerty/design-system/src/_variables";

.container {
  --carHeight: 112px;
  --carBottomMargin: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .titleArea {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin: 0;
      padding: 0;
      font-stretch: expanded;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
    }

    p {
      margin: 0;
      padding: 16px 0 24px 0;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
  }

  .button {
    display: inline-flex;
    align-items: center;
    line-height: unset;

    svg {
      margin: 0 8px 0 0;
      stroke: currentColor;
      fill: none;
    }
  }

  .line {
    display: none;
  }

  .road,
  .car {
    margin: 56px 0 32px 0;
    width: 248px;
    height: var(--carHeight);
  }

  .road {
    width: calc(100% - 64px);
    left: 32px;
    right: 32px;
  }

  // Move the car up to the road
  .car {
    margin-top: calc(0px - (var(--carHeight) + var(--carBottomMargin)));
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    span {
      color: $color-dark-1;
      font-weight: 500;
    }

    a {
      line-height: 26px;
      svg {
        margin-left: 8px;
      }
    }
  }
}

// Mobile
@media (max-width: 767px) {
  .titleArea {
    width: 327px;
  }
}
