@import "../../../theme/_base";

.container {
  margin-top: 16px;

  @media (min-width: 480px) {
    margin-top: 32px;
  }
}

.skeleton {
  height: 26px;

  @include skeleton;

  &:first-child {
    width: 120px;
  }

  &:last-child {
    width: 75px;
  }
}

.skeletonHeader {
  width: 200px;
  height: 35px;
  @include skeleton;

  @media (min-width: 480px) {
    width: 300px;
    height: 40px;
  }
}

.skeletonTitle {
  width: 136px;
  height: 26px;

  @media (min-width: 480px) {
    width: 200px;
    height: 26px;
  }

  @include skeleton;
}

.sectionDetailRow {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: $color-dark-1;
  padding: 4px 0;

  @media (min-width: 480px) {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
    padding: 8px 0;
  }
}

.paper {
  background-color: var(--white);
  max-width: 750px;
  margin: auto;
  margin-top: auto;
}
