@import "@hagerty/design-system/src/_variables";

// use both selectors so that we can override css specificity
.sticky.submenu {
  position: sticky;

  li {
    padding-left: 0;
    margin-left: 0;
    font-weight: 500;

    a {
      display: flex;
      align-items: center;
    }
  }
}

.menuWrapper {
  flex-direction: row;
  display: flex;
}

.menuImage {
  flex-direction: row;
  display: flex;
  padding-right: 16px;
}

.submenu__nav {
  list-style: none;
  padding: 16px 0 0;
  margin: 0;
  white-space: nowrap;
  overflow-x: scroll;
  margin-left: 8px;
}

// Desktop
@media (min-width: 768px) {
  .sticky.submenu {
    top: 130px;
    padding-left: 0; // align submenu text with Hagerty logo on left
    min-width: 100px;
  }

  .submenu__nav {
    display: inline-flex;
    flex-direction: column;
    margin-top: -8px;
    padding: 0 32px 16px 0;
    white-space: inherit;
    overflow-x: inherit;

    li {
      padding: 8px 0;

      a {
        color: $color-dark-1;

        svg {
          fill: currentColor;
        }

        &[aria-current="location"] {
          color: $color-brand;
        }

        &:not([aria-current="location"]):hover {
          color: $color-dark-2;
        }

        &:focus-visible {
          outline: 2px solid $color-brand;
        }

        // override the pill when active
        &:active,
        &:focus,
        .active .submenu__link {
          border-radius: 0;
          background-color: inherit;
        }
      }
    }
  }
}

// Mobile
@media (max-width: 767px) {
  .sticky.submenu {
    display: none;
  }

  .menuWrapper {
    display: inline-block;
  }

  .submenu__nav {
    margin-left: 0;
  }

  /* Hide the submenu image on smaller screen widths */
  .menuImage {
    display: none;
  }
}
